<template>
  <div :class="!$vuetify.breakpoint.smAndDown && 'accent rounded-lg pa-8'">
    <molecules-custom-dialog
      :show="showRemove"
      title="Apakah Anda Yakin?"
      :caption="`Periksa kembali sebelum menghapus`"
      :items="[
        {
          title: 'Batalkan',
          full: false,
          exec: () => (showRemove = null),
        },
        {
          title: 'Ya, Lanjutkan',
          override: 'red--text',
          full: false,
          exec: () => {
            showRemove()
            showRemove = null
          },
        },
      ]"
    />
    <molecules-custom-dialog
      :show="showValidator"
      title="Apakah Anda Yakin?"
      :caption="`Total Pesanan anda sebesar ${$idCurrency(
        totalOffer,
        2,
        2,
      )} dengan Nilai Titipan Dana Awal sebesar ${getDownPayment} `"
      :items="[
        {
          title: 'Batalkan',
          full: false,
          exec: () => (showValidator = false),
        },
        {
          title: 'Ya, Lanjutkan',
          override: 'red--text',
          full: false,
          exec: () => {
            showValidator = false
            this.validate()
          },
        },
      ]"
    />
    <v-container>
      <v-form ref="form" v-model="valid" @submit.prevent="preValidate">
        <v-row align="center" justify="space-between">
          <v-col cols="12" md="10" align="center">
            <atoms-text-field
              v-if="!$store.state.client.data || !client.enabled"
              label="Cari Client"
              :color="$vuetify.breakpoint.smAndDown ? 'accent' : 'background'"
              v-model="client.term"
              :appendAction="
                () => {
                  client.term = ''
                  client.enabled = false
                }
              "
              :rules="$vuetify.breakpoint.smAndDown ? undefined : []"
            />
            <atoms-text-field v-else label="Pilih Client">
              <v-autocomplete
                class="rounded-lg"
                v-model="client.value"
                :background-color="$vuetify.breakpoint.smAndDown ? 'accent' : 'background'"
                :items="$store.state.client.data.map((x) => x.name)"
                placeholder="Pilih Client"
                :menu-props="{ offsetY: true }"
                :rules="[(v) => !!v || 'Harap disii!']"
                dense
                solo
                flat
              />
            </atoms-text-field>
          </v-col>
          <v-col cols="12" md="2" align="center" class="d-flex align-center">
            <atoms-button
              @click="
                () =>
                  !$store.state.client.data || !client.enabled
                    ? getClients()
                    : (function () {
                        items = []
                        client.value = ''
                        client.enabled = false
                        client.item = null
                      })()
              "
              :loading="$store.state.loading"
              :style="{ width: '100%' }"
              :class="[!$store.state.client.data || !client.enabled ? 'primary' : 'red white--text']"
              >{{ !$store.state.client.data || !client.enabled ? 'Cari Client' : 'Reset Pencarian' }}</atoms-button
            >
          </v-col>
        </v-row>
        <v-divider v-if="!$vuetify.breakpoint.smAndDown" class="py-2" />
        <v-row class="text-left">
          <v-col cols="12" md="6">
            <v-dialog
              ref="pickerCurrentDialog"
              v-model="current.modal"
              :return-value.sync="current.value"
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <atoms-text-field label="Tanggal" :rules="[]">
                  <v-text-field
                    class="rounded-lg"
                    placeholder="Tanggal"
                    append-icon="mdi-calendar"
                    :background-color="$vuetify.breakpoint.smAndDown ? 'accent' : 'background'"
                    v-model="formatCurrent"
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                    readonly
                    dense
                    solo
                    flat
                  />
                </atoms-text-field>
              </template>
              <v-date-picker
                v-model="current.value"
                color="primary"
                scrollable
                class="rounded-lg"
                @click:date="$refs.pickerCurrentDialog.save(current.value)"
              />
            </v-dialog>
          </v-col>
          <v-col cols="12" md="6">
            <v-dialog ref="pickerDueDialog" v-model="due.modal" :return-value.sync="due.value" width="290px">
              <template v-slot:activator="{ on, attrs }">
                <atoms-text-field label="Batas Tanggal" :rules="[]">
                  <v-text-field
                    class="rounded-lg"
                    placeholder="Batas Tanggal"
                    append-icon="mdi-calendar"
                    :background-color="$vuetify.breakpoint.smAndDown ? 'accent' : 'background'"
                    v-model="formatDueDate"
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                    readonly
                    dense
                    solo
                    flat
                  />
                </atoms-text-field>
              </template>
              <v-date-picker
                v-model="due.value"
                scrollable
                color="primary"
                class="rounded-lg"
                @click:date="$refs.pickerDueDialog.save(due.value)"
              />
            </v-dialog>
          </v-col>
        </v-row>
        <v-row justify="center" class="my-0">
          <v-col cols="12" md="12">
            <atoms-text-field
              :label="isInvoice ? 'Nomor Invoice' : 'Nomor Kuotasi'"
              readonly
              v-model="nomorQuo"
              :color="$vuetify.breakpoint.smAndDown ? 'accent' : 'background'"
            />
          </v-col>
          <v-col cols="12" class="d-flex flex-column align-center">
            <atoms-title :h3="true">Tipe Pesanan</atoms-title>
            <v-radio-group v-model="type" mandatory dense row>
              <v-radio label="Processing" :value="$inputType.processing" />
              <v-radio label="Sprinter" :value="$inputType.sprinter" />
            </v-radio-group>
          </v-col>
          <v-col
            class="d-md-flex align-center justify-space-between py-0"
            :align="$vuetify.breakpoint.smAndDown ? 'center' : 'left'"
          >
            <div class="d-flex align-md-center flex-column flex-md-row">
              <atoms-text class="font-weight-bold">Pesanan {{ isInvoice ? 'Invoice' : 'Kuotasi' }} </atoms-text>
              <div class="my-4 mx-0 my-md-0 mx-md-4">
                <atoms-button
                  :disabled="Boolean(!client.item || !client.enabled)"
                  @click="showAddOne = true"
                  :style="{
                    width: $vuetify.breakpoint.smAndDown ? '100%' : 'auto',
                  }"
                  class="primary mb-2 mb-md-0"
                  ><v-icon class="mr-2">mdi-plus-box</v-icon>Tambah Pesanan
                </atoms-button>
              </div>
            </div>

            <div class="d-flex align-center">
              <div class="d-flex align-center justify-center">
                <v-checkbox v-model="isPpn" hide-spin-buttons />
              </div>
              <v-label><atoms-text class="pa-2 font-weight-bold">Aktifkan PPN : </atoms-text></v-label>
              <atoms-text-field
                :disabled="!isPpn"
                label="Persentase PPN"
                :noLabel="true"
                v-model="ppn"
                :color="$vuetify.breakpoint.smAndDown ? 'accent' : 'background'"
              />
            </div>
            <div class="d-flex align-center">
              <div class="d-flex align-center justify-center">
                <v-checkbox v-model="isPph" hide-spin-buttons />
              </div>
              <v-label><atoms-text class="pa-2 font-weight-bold">Aktifkan PPH : </atoms-text></v-label>
              <atoms-text-field
                :disabled="!isPph"
                label="Persentase PPH"
                :noLabel="true"
                v-model="pph"
                :color="$vuetify.breakpoint.smAndDown ? 'accent' : 'background'"
              />
            </div>
          </v-col>
          <v-col cols="12">
            <v-data-table
              :headers="[
                {
                  text: 'Judul',
                  sortable: false,
                  value: 'title',
                },
                {
                  text: 'Area',
                  sortable: false,
                  value: 'areaTitle',
                },
                {
                  text: 'Gudang / Lokasi',
                  sortable: false,
                  value: 'location.name',
                },
                {
                  text: 'Jumlah Pekerja',
                  sortable: false,
                  value: 'amount',
                },
                {
                  text: 'Jumlah Hari',
                  sortable: false,
                  value: 'quantity',
                },
                {
                  text: 'Harga Modal',
                  sortable: false,
                  value: 'priceBase',
                },
                {
                  text: 'Fee',
                  sortable: false,
                  value: 'fee',
                },
                {
                  text: 'Harga Penawaran ',
                  sortable: false,
                  value: 'priceProposed',
                },
                {
                  text: 'Total Modal',
                  sortable: false,
                  value: 'totalBase',
                },
                {
                  text: 'Total Fee',
                  sortable: false,
                  value: 'totalFee',
                },
                {
                  text: 'Total Penawaran ',
                  sortable: false,
                  value: 'totalProposed',
                },
                {
                  text: 'Edit',
                  sortable: false,
                  value: 'action',
                },
                {
                  text: 'Hapus',
                  sortable: false,
                  value: 'action2',
                },
              ]"
              :items="items"
              hide-default-footer
              hide-default-header
              :class="[($vuetify.breakpoint.smAndDown && 'accent') || 'background', 'rounded-lg']"
              mobile-breakpoint="0"
              disable-pagination
            >
              <template v-slot:[`header`]="{ props }">
                <thead>
                  <tr class="d-table-row">
                    <th v-for="h in props.headers" :key="h.value" :class="['text-center', h.class]">
                      <span class="primary--text text-uppercase" v-html="h.text" />
                    </th>
                  </tr></thead
              ></template>

              <template v-slot:[`item`]="{ item }">
                <tr class="d-table-row text-center">
                  <td>{{ item.title }}</td>
                  <td>{{ item.areaTitle }}</td>
                  <td>{{ item.location?.name ?? item.location }}</td>
                  <td>{{ item.amount }}</td>
                  <td>{{ item.quantity }}</td>
                  <td>
                    {{ $idCurrency(String(item.priceBase)) }}
                  </td>
                  <td>
                    {{ $idCurrency(String(item.fee)) }}
                  </td>
                  <td>
                    {{ $idCurrency(String(item.priceProposed)) }}
                  </td>
                  <td>
                    {{ $idCurrency(String(item.totalBase)) }}
                  </td>
                  <td>
                    {{ $idCurrency(String(item.totalFee)) }}
                  </td>
                  <td>
                    {{ $idCurrency(String(item.totalProposed)) }}
                  </td>
                  <td>
                    <v-icon
                      @click="
                        () => {
                          editableId = item._id
                          showAddOne = true
                        }
                      "
                      small
                      class="mx-2"
                    >
                      mdi-pencil
                    </v-icon>
                  </td>
                  <td>
                    <v-icon
                      @click="
                        showRemove = async () => {
                          try {
                            const isShiftExist = await $api
                              .get(`Absensi/checkShift/${item._id}`)
                              .then((res) => res.result)
                            if (isShiftExist) {
                              return $store.commit('dialog/show', {
                                title: 'Perhatian',
                                body: 'Shift ini telah dipakai absensi, tidak bisa dihapus!',
                              })
                            } else if (isShiftExist === false) {
                              items = items.filter((x) => x._id !== item._id)
                              reCountTotalOffer()
                            }
                          } catch (err) {
                            return $store.commit('dialog/show', {
                              title: 'Perhatian',
                              body:
                                err?.response?.data?.message ||
                                err?.message ||
                                'Terdapat gangguan server, coba lagi nanti',
                            })
                          }
                        }
                      "
                      small
                      class="mx-2"
                    >
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
          <!-- exception -->
          <v-col cols="12">
            <v-divider />
          </v-col>
          <v-col
            class="d-md-flex align-center justify-space-between"
            :align="$vuetify.breakpoint.smAndDown ? 'center' : 'left'"
          >
            <div>
              <atoms-text class="font-weight-bold mb-4 mb-md-0">Pengurangan Tagihan (Bila Ada)</atoms-text>
            </div>
            <div class="d-flex flex-column flex-md-row justify-center align-center">
              <v-label><atoms-text class="pa-2 font-weight-bold">Keterangan : </atoms-text></v-label>
              <v-text-field
                v-model="exception.title"
                class="rounded-lg"
                :background-color="$vuetify.breakpoint.smAndDown ? 'accent' : 'background'"
                :style="$vuetify.breakpoint.smAndDown && 'width:100%'"
                placeholder="Keterangan"
                hide-details
                solo
                flat
                dense
              />
              <v-label><atoms-text class="pa-2 font-weight-bold">Nominal : </atoms-text></v-label>
              <v-text-field
                dense
                class="rounded-lg"
                :background-color="$vuetify.breakpoint.smAndDown ? 'accent' : 'background'"
                :style="$vuetify.breakpoint.smAndDown && 'width:100%'"
                placeholder="Pengurangan Nominal"
                v-model="exception.price"
                prefix="Rp."
                hide-details
                solo
                flat
              />
              <atoms-button
                @click="
                  () => {
                    if (exception.price && Number(exception.price.replace('.', '')) > 0 && exception.title) {
                      exceptions.push({
                        ...exception,
                        _id: $uuid.v4(),
                        price: exception.price.replace('.', ''),
                      })
                      reCountTotalOffer()
                    } else {
                      $store.commit('dialog/show', {
                        title: 'Perhatian',
                        body: 'Pastikan kolom keterangan dan nominal pengurangan terisi!',
                      })
                    }
                    exception = {}
                  }
                "
                :style="{
                  width: $vuetify.breakpoint.smAndDown ? '100%' : 'auto',
                }"
                class="primary px-md-8 ml-md-4 mt-4 mt-md-0"
                ><v-icon left>mdi-plus-box</v-icon>Pengurangan</atoms-button
              >
            </div>
          </v-col>
          <v-col v-show="exceptions.length > 0" cols="12">
            <v-data-table
              :headers="[
                {
                  text: 'Keterangan',
                  sortable: false,
                  value: 'title',
                },
                {
                  text: 'Nominal',
                  sortable: false,
                  value: 'price',
                },
                {
                  text: 'Aksi',
                  sortable: false,
                  value: 'action',
                },
              ]"
              :items="exceptions"
              class="rounded-lg"
              hide-default-footer
              hide-default-header
              disable-pagination
            >
              <template v-slot:[`header`]="{ props }">
                <thead>
                  <tr class="d-table-row">
                    <th v-for="h in props.headers" :key="h.value" :class="['text-center', h.class]">
                      <span class="primary--text text-uppercase">{{ h.text }}</span>
                    </th>
                  </tr>
                </thead></template
              >

              <template v-slot:[`item`]="{ item }">
                <tr class="d-table-row text-center">
                  <td>{{ item.title }}</td>
                  <td>
                    {{ $idCurrency(String(item.price)) }}
                  </td>
                  <td>
                    <!-- totalOffer += Number(item.nominal); -->
                    <v-icon
                      @click="
                        showRemove = () => {
                          exceptions = exceptions.filter((x) => x._id !== item._id)
                          reCountTotalOffer()
                        }
                      "
                      light
                      small
                    >
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12">
            <v-divider />
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="printVersion"
              :items="[
                {
                  label: 'Versi 1 (PPN/PPH berdasarkan Platform Fee)',
                  value: 1,
                },
                {
                  label: 'Versi 2 (PPN/PPH berdasarkan Total Penawaran)',
                  value: 2,
                },
              ]"
              item-text="label"
              item-value="value"
              :menu-props="{ offsetY: true }"
              label="Print Version"
              class="rounded-lg accent"
              outlined
              dense
              hide-details
            />
          </v-col>
          <v-col cols="12">
            <v-divider />
          </v-col>
        </v-row>
        <!-- caps -->
        <v-row>
          <v-col align="end">
            <atoms-title :h2="true">{{ 'Total Modal' }}</atoms-title>
            <atoms-title :h2="true">{{ 'Platform Fee' }}</atoms-title>
            <atoms-title :h2="true">{{ 'Total Penawaran' }}</atoms-title>
            <atoms-title v-if="isPpn" :h2="true">{{ `PPN` }}</atoms-title>
            <atoms-title v-if="isPph" :h2="true">{{ `PPH` }}</atoms-title>
            <atoms-title :h2="true">{{ 'Total' }}</atoms-title>
          </v-col>
          <v-col align="start" class="shrink">
            <atoms-title :h2="true">:</atoms-title>
            <atoms-title :h2="true">:</atoms-title>
            <atoms-title :h2="true">:</atoms-title>
            <atoms-title v-if="isPpn" :h2="true">:</atoms-title>
            <atoms-title v-if="isPph" :h2="true">:</atoms-title>
            <atoms-title :h2="true">:</atoms-title>
          </v-col>
          <v-col align="end" class="shrink">
            <atoms-title :h2="true">Rp.</atoms-title>
            <atoms-title :h2="true">Rp.</atoms-title>
            <atoms-title :h2="true">Rp.</atoms-title>
            <atoms-title v-if="isPpn" :h2="true">Rp.</atoms-title>
            <atoms-title v-if="isPph" :h2="true">Rp.</atoms-title>
            <atoms-title :h2="true">Rp.</atoms-title>
          </v-col>
          <v-col align="end" class="shrink">
            <atoms-title :h2="true">{{ $idCurrency(totalFund, 2, 2, true).replace('Rp', '') }}</atoms-title>
            <atoms-title :h2="true">{{ $idCurrency(profit, 2, 2, true).replace('Rp', '') }}</atoms-title>
            <atoms-title :h2="true">{{ $idCurrency(pureTotalOffer, 2, 2, true).replace('Rp', '') }}</atoms-title>
            <atoms-title v-if="isPpn" :h2="true">{{ $idCurrency(ppnValue, 2, 2, true).replace('Rp', '') }}</atoms-title>
            <atoms-title v-if="isPph" :h2="true">{{ $idCurrency(pphValue, 2, 2, true).replace('Rp', '') }}</atoms-title>
            <atoms-title :h2="true">{{ $idCurrency(totalOffer, 2, 2, true).replace('Rp', '') }}</atoms-title>
          </v-col>
        </v-row>

        <!-- persentase -->
        <v-row>
          <v-col cols="12">
            <v-divider />
          </v-col>
          <v-col cols="12" md="6">
            <atoms-text-field
              label="Persentase Titipan Dana Awal"
              v-model="dpConvertPercentage"
              :color="$vuetify.breakpoint.smAndDown ? 'accent' : 'background'"
            />
          </v-col>
          <v-col cols="12" md="6">
            <atoms-text-field
              label="Nilai Titipan Dana Awal"
              v-model="getDownPayment"
              :color="$vuetify.breakpoint.smAndDown ? 'accent' : 'background'"
              :readonly="true"
            />
          </v-col>
          <v-col cols="12" v-if="isInvoice">
            <atoms-text-field
              label="Realisasi DP"
              v-model="invoiceDownPayment"
              :color="$vuetify.breakpoint.smAndDown ? 'accent' : 'background'"
            />
          </v-col>
          <v-col cols="12">
            <atoms-button
              :disabled="Boolean(!client.enabled || !client.item)"
              style="width: 100%"
              type="submit"
              class="primary mr-md-4 mb-2 mb-md-0"
              ><v-icon class="mr-2">mdi-note-check</v-icon> Simpan {{ isInvoice ? 'Invoice' : 'Kuotasi' }}</atoms-button
            >
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-slide-x-reverse-transition>
      <organism-quotation-items
        v-if="showAddOne"
        :type="type === $inputType.sprinter ? true : false"
        :datas="editableId ? items.find((x) => x._id === editableId) : null"
        :client="client && client.item"
        @closed="onQuoItemsClosed"
      />
    </v-slide-x-reverse-transition>
    <!-- <molecules-helper-generate-quotation
      v-if="showHelper"
      @closed="showHelper = false"
      :show="showHelper"
    /> -->
  </div>
</template>
<script>
export default {
  props: {
    isInvoice: {
      type: Boolean,
    },
  },
  data() {
    return {
      printVersion: 2,
      valid: true,
      showAddOne: false,
      showHelper: true,
      showValidator: false,
      showRemove: null,
      editableId: '',
      items: [],
      exceptions: [],
      exception: {},
      isPpn: this.isInvoice ? true : false,
      ppn: '11%',
      ppnValue: 0,
      isPph: this.isInvoice ? true : false,
      pph: '2%',
      pphValue: 0,
      type: '',
      totalFund: 0,
      totalOffer: 0,
      pureTotalOffer: 0,
      marginPercentage: 0,
      profit: 0,
      downpayment: 0,
      invoiceDownPayment: '',
      dpPercentage: 1,
      dpConvertPercentage: '100%',
      nomorQuo: '',
      current: {
        modal: false,
        value: this.$moment().format('YYYY-MM-DD'),
      },
      hkDesc: null,
      due: {
        modal: false,
        value: this.$moment().add(1, 'days').format('YYYY-MM-DD'),
      },
      client: {
        term: '',
        enabled: false,
        value: '',
        item: null,
      },
    }
  },
  watch: {
    async 'client.value'() {
      if (this.client.value) {
        this.$store.commit('SET', { loading: true })
        this.client.item = this.$store.state.client?.data?.find((x) => x.name === this.client.value)
        await this.quoNumAutoChanger()
        this.$store.commit('SET', { loading: false })
      }
    },
    'exception.price': function (newValue) {
      const result = newValue ? newValue.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ''
      this.$nextTick(() => (this.exception.price = result))
    },
    exceptions: {
      handler() {
        this.reCountTotalOffer()
      },
      deep: true,
    },
    invoiceDownPayment: function (newValue) {
      const result = newValue ? newValue.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ''
      this.$nextTick(() => {
        this.invoiceDownPayment = result
      })
    },
    nomorQuo: function (value) {
      this.$nextTick(() => {
        if (!this.nomorQuo) {
          this.quoNumAutoChanger()
        }
      })
    },
    items: {
      handler() {
        this.reCountTotalOffer()
      },
      deep: true,
    },
    isPpn: function () {
      this.reCountTotalOffer()
    },
    ppn: function () {
      this.reCountTotalOffer()
    },
    isPph: function () {
      this.reCountTotalOffer()
    },
    pph: function () {
      this.reCountTotalOffer()
    },
    dpPercentage: function (value) {
      this.reCountTotalOffer()
    },
    'current.value': function (newValue) {
      if (newValue) {
        this.quoNumAutoChanger()
      }
    },
    'due.value': function (newValue) {
      if (newValue) {
        this.quoNumAutoChanger()
      }
    },
    dpConvertPercentage: function (value) {
      try {
        const converter = (value.includes('%') && this.$convertPercenttoDecimal(value)) || Number(value) / 100
        this.$nextTick(() => {
          this.dpConvertPercentage = this.$convertDecimaltoPercent((converter > 1 && 1) || converter || 1)
          this.dpPercentage = (converter > 1 && 1) || converter || 1
        })
      } catch (error) {
        throw error
      }
    },
    printVersion: function (value) {
      this.reCountTotalOffer()
    },
  },
  computed: {
    getDownPayment: {
      get() {
        return this.$idCurrency(this.downpayment)
      },
      set() {
        // prevent error
      },
    },
    formatCurrent: {
      set(value) {
        this.current.value = this.$moment(value).format('YYYY-MM-DD')
      },
      get() {
        return this.$moment(this.current.value).format('DD MMMM YYYY')
      },
    },
    formatDueDate: {
      set(value) {
        this.due.value = this.$moment(value).format('YYYY-MM-DD')
      },
      get() {
        return this.$moment(this.due.value).format('DD MMMM YYYY')
      },
    },
  },
  async mounted() {
    this.$store.commit('SET', { loading: true })
    try {
      if (this.$route.params?._id) {
        this.items = []
        const datas = (
          await this.$store.dispatch('quotation/get', {
            query: { _id: this.$route.params?._id },
            limit: 1,
            dont_set: true,
          })
        )?.[0]

        // console.log(datas.items?.map((_item) => _item._id))
        if (datas) {
          this.items = Array.apply([], datas.items)?.map((x) => {
            if (x?.workerIds) {
              delete x.workerIds
            }
            if (x?.attendanceIds) {
              delete x.attendanceIds
            }
            if (x?.processedShift) {
              delete x.processedShift
            }
            if (x?.isCreatedByClient) {
              delete x.isCreatedByClient
            }
            if (x?.assignedWorkerIds) {
              delete x.assignedWorkerIds
            }
            if (x?.qrCode) {
              delete x.qrCode
            }
            if (x?.quotationId) {
              delete x.quotationId
            }
            return {
              ...x,
              _id: this.$uuid.v4(),
              note: `Renewal dari items kuotasi ${datas?.no || this.$route.params?._id}`,
              totalProposed: Number(x.pureTotalProposed),
            }
          })

          // prevent for same Ids
          let blacklist = this.items?.filter((_item) => datas.items?.find((__item) => __item._id === _item._id))
          if (blacklist?.length > 0) {
            this.items = this.items?.filter((_item) => !blacklist?.find((__item) => __item?._id == _item?._id))
          }

          // console.log(this.items?.map((_item) => _item?._id))

          this.client.item = (
            await this.$store.dispatch('client/getAll', {
              query: { _id: datas?.clientId },
            })
          )?.[0]
          this.hkDesc = `Kuotasi renewal dari ${datas?.no || this.$route.params?._id}`
          this.client.value = this.client?.item?.name
          this.client.term = this.client?.value
          this.client.enabled = (this.client?.value && this.client?.item && true) || false
          this.type = datas?.type || ''
          this.exceptions = datas?.priceReduction || []
          this.formatCurrent = this.$moment(datas?.date)
          this.formatDueDate = this.$moment(datas?.dueDate || new Date())
          this.isPpn = datas?.isActivePpn
          this.ppn = (datas?.ppnPercentage && this.$convertDecimaltoPercent(datas?.ppnPercentage)) || this.ppn
          this.ppnValue = datas?.ppnValue
          this.isPph = datas?.isActivePph
          this.pph = (datas?.pphPercentage && this.$convertDecimaltoPercent(datas?.pphPercentage)) || this.pph
          this.pphValue = datas?.pphValue
          this.dpPercentage = datas?.dpPercentage || this.dpPercentage
          this.dpConvertPercentage = this.$convertDecimaltoPercent(datas?.dpPercentage) || this.dpConvertPercentage
          if (!datas?.date || !datas?.dueDate) {
            this.$showDialog({
              title: 'Perhatian',
              body: `Terdapat kesalahan pada tanggal, silahkan cek kembali apakah sudah sesuai atau belum, kemudian simpan untuk membuat perubahan.`,
            })
          }
        }
      }
    } catch (error) {
      return Promise.reject(error)
    } finally {
      this.$store.commit('SET', { loading: false })
    }
  },
  methods: {
    justConsole(payload) {
      // console.log(payload || this.items);
    },
    async onQuoItemsClosed(payload) {
      let check = payload && this.items?.find((x) => x._id === payload._id)
      if (payload && check) {
        this.items = [...this.items?.filter((x) => x._id !== check._id), payload]
      } else {
        payload && this.items.push(payload)
      }
      // console.log(this.items)
      this.showAddOne = false
      this.editableId = ''
    },
    async getClients() {
      this.$store.commit('SET', { loading: true })
      const term = { $regex: this.client.term || '', $options: 'i' }
      try {
        await this.$store.dispatch('client/getAll', {
          custom: {
            approved: true, //{ $exists: true }
            $or: [
              {
                name: term,
              },
              {
                'address.streetAddress1': term,
              },
              {
                'contactPerson.name': term,
              },
              {
                'contactPerson.email': term,
              },
              {
                code: term,
              },
            ],
            pipeline: [
              {
                $lookup: {
                  from: 'Areas',
                  localField: '_id',
                  foreignField: 'clientId',
                  as: 'areas',
                },
              },
            ],
          },
          page: 1,
          limit: 0,
        })
        this.client.enabled = true
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async quoNumAutoChanger() {
      this.$store.commit('SET', { loading: true })
      this.nomorQuo = await this.$autoFillQuoNum(
        this.client?.item?._id || 0,
        new Date(this.$moment(this.current.value)),
        this.client?.item?.code || '',
        this.isInvoice,
      )
      this.$store.commit('SET', { loading: false })
    },
    reCountTotalOffer() {
      if (this.items.length > 0) {
        // calc
        this.totalOffer = this.items.map((x) => Number(x.totalProposed || 0)).reduce((past, current) => past + current)
        this.totalFund = this.items.map((x) => Number(x.totalBase || 0)).reduce((past, current) => past + current)
        this.pureTotalOffer = this.items
          .map((x) => Number(x.pureTotalProposed || 0))
          .reduce((past, current) => past + current)
        this.profit = Number(this.pureTotalOffer || 0) - Number(this.totalFund || 0)
        this.marginPercentage = this.profit / this.pureTotalOffer
        // calc taxes
        const valueGI = this.profit
        if (this.isPpn) {
          if (this.printVersion === 2) {
            this.ppnValue = Math.floor(this.$convertPercenttoDecimal(this.ppn) * this.pureTotalOffer)
          } else {
            this.ppnValue = Math.floor(this.$convertPercenttoDecimal(this.ppn) * valueGI)
          }
        } else {
          this.ppnValue = 0
        }
        if (this.isPph) {
          if (this.printVersion === 2) {
            this.pphValue = Math.floor(this.$convertPercenttoDecimal(this.pph) * this.pureTotalOffer)
          } else {
            this.pphValue = Math.floor(this.$convertPercenttoDecimal(this.pph) * valueGI)
          }
        } else {
          this.pphValue = 0
        }
        // calc total offer after taxes and price reduction
        const totalPriceReduction = this.exceptions.reduce((total, item) => total + +(item?.price || 0), 0)
        this.totalOffer = this.pureTotalOffer + (+this?.ppnValue || 0) - (+this?.pphValue || 0) - totalPriceReduction
      }
      this.calculateDownPayment()
    },
    calculateDownPayment() {
      const dpDecimal = this.$convertPercenttoDecimal(this.dpPercentage)
      this.downpayment = this.totalOffer * dpDecimal
    },
    getQuotationModel() {
      this.reCountTotalOffer()
      const userId = this.$store.state.user?._id
      const payload = {
        _id: this.$uuid.v4(),
        no: this.nomorQuo,
        printVersion: this.printVersion,
        clientId: this.client.item?._id,
        date: this.current?.value || new Date(),
        dueDate: this.due?.value || new Date(new Date().setDate(new Date().getDate() + 1)),
        type: this.type,
        totalFund: this.totalFund,
        totalOffer: this.totalOffer,
        pureTotalOffer: this.pureTotalOffer,
        marginPercentage: this.marginPercentage,
        profit: this.profit,
        isActivePpn: this.isPpn,
        ppnPercentage: this.$convertPercenttoDecimal(this.ppn),
        ppnValue: this.ppnValue,
        isActivePph: this.isPph,
        pphPercentage: this.$convertPercenttoDecimal(this.pph),
        pphValue: this.pphValue,
        dpPercentage:
          (this.dpConvertPercentage && this.$convertPercenttoDecimal(this.dpConvertPercentage)) || this.dpPercentage,
        downpayment: this.downpayment,
        priceReduction: this.exceptions || [],
        createdBy: userId,
        updatedBy: userId,
        hkDesc: this.hkDesc || undefined,
      }
      if (this.isInvoice) {
        payload.no = this.nomorQuo.replace('/INV/', '/QUO/')
        payload.hkApproved = true
        payload.clientApproved = true
        payload.hkApproveBy = userId
        payload.clientApproveBy = userId
      }
      return payload
    },
    async quotationItemPurified(quotationId) {
      return !quotationId
        ? Promise.reject('quotation not defined!')
        : this.items.map((x) => {
            return {
              quotationId,
              ...x,
            }
          })
    },
    async preValidate() {
      await this.$refs.form.validate()
      if (this.valid && this.items?.length > 0 && this.nomorQuo && this.client?.item) {
        this.showValidator = true
      } else if (this.items?.length <= 0) {
        this.$showDialog({
          title: 'Perhatian',
          body: `Pastikan terdapat items, setidaknya satu!`,
        })
      } else {
        this.$showDialog({
          title: 'Perhatian',
          body: `Periksa kembali kolom pengisian!`,
        })
      }
    },
    async validate() {
      await this.quoNumAutoChanger()
      // harus paling atas ^
      this.$store.commit('SET', { loading: true })
      try {
        let quotationData = this.getQuotationModel()
        let quotationItems = await this.quotationItemPurified(quotationData?._id)

        // console.log('result-quo', quotationData, quotationItems)
        // create quotation
        quotationData = await this.$store.dispatch('quotation/create', quotationData)
        await this.$store.dispatch('quotation/saveItems', quotationItems)
        // .then((_items) => {
        //   // console.log('result-quo-items', _items)
        // })

        // create invoice
        let savedInvoice
        if (this.isInvoice) {
          const quotationId = quotationData?._id
          const payload = {
            ...quotationData,
            _id: quotationId,
            quotationId,
            no: this.nomorQuo.replace('/QUO/', '/INV/'),
            dpPaymentDate: new Date(),
            downpayment: this.$removeSeparator(this.invoiceDownPayment),
            dpProof: '',
            dpSubmitBy: this.$store.state.user?._id,
          }

          // @save invoice
          savedInvoice = await this.$store.dispatch('invoice/save', payload)
          // @save invoice items
          // console.log('result-inv', savedInvoice)
          const invoiceItems = quotationItems.map((item) => {
            return {
              ...item,
              invoiceId: savedInvoice?._id,
              quotationId: savedInvoice?.quotationId,
              quoItemId: item._id,
            }
          })
          await this.$store.dispatch('invoice/saveItems', invoiceItems)
          // .then((_items) => {
          //   // console.log('result-inv-items', _items)
          // })
        }
        this.$showDialog({
          title: 'Berhasil',
          body: `${this.isInvoice ? 'Invoice' : 'Kuotasi'} baru berhasil disimpan!`,
        })

        // console.log(quotationData, savedInvoice)
        if (this.isInvoice) {
          this.$router.push(`/invoices/review?_id=${savedInvoice?._id}`)
          window.open(`/cetak-invoice?type=invoice&no=${savedInvoice?._id}`)
        } else {
          this.$router.push(`/quotations/review?_id=${quotationData?._id}`)
          window.open(`/cetak-kuotasi?type=quotation&no=${quotationData?._id}`)
        }

        // this.$router.push('/quotations/review')
      } catch (error) {
        return Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
  },
}
</script>
